#breadcrumb {
    background-color: #ebeee9;
    .breadcrumb-container {        
        ol {
            text-align: center;
            background: none; 
            margin: 0;
            padding: 9px;
            li {
                color: @darkblue;  
                a {
                    color: @gold;
                    text-decoration: underline;
                }
                &.active {
                    color: @darkblue;
                }
            }
        }
    }
}
