body.template-news {
    main {
        #header {
            overflow: hidden;
            padding: 77px 0 80px;
            background-position: center center;
            position: relative;
            .bg-cover();
            .colour-overlay {
                background-color: rgba(35, 56, 23, 0.85);
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 1;
            } 
            h1 {
                text-align: center;
                color: #fff;
                position: relative;
                z-index: 2;
                margin: 0;
                font-weight: 300;
                padding: 0 0 4px;
                font-size: 32px;
            }
            h2 {
                text-align: center;
                font-size: 27px;
                color: #fff;
                position: relative;
                z-index: 2;
                margin: 0;
                font-weight: 300;
            }  
        }
        #feed {
            background-color: #f3f5f1;
            padding-bottom: 30px;
            .container {
                .single-news {
                    background-color: #fff;
                    margin-top: 35px;
                    padding: 23px;
                    .title {
                        font-size: 24px;
                        line-height: 24px;
                        font-weight: 400;
                        margin: 0;
                        padding: 0 0 7px;
                    }
                    .date {
                        font-size: 18px;
                        font-weight: 300;
                        padding: 0 0 17px;
                    }
                    .content {
                        font-size: 20px;
                        line-height: 28px;
                        font-weight: 300;
                    }
                    a {
                        background-color: #e6ae34;
                        color: #fff;
                        font-size: 16px;
                        line-height: 35px;
                        display: inline-block;
                        padding: 0 13px;
                        margin: 13px 0 4px;
                    }
                }
            }
        }
    }
}