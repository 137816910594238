body.template-technicalhelp {
    main {
        #top {
            background-color: #ebeee9;
            padding: 71px 0 45px;
            .container {
                .row {
                    h1 {
                        text-align: center;
                        font-size: 32px;
                        margin: 0 0 9px;
                    }
                    h2 {
                        text-align: center;
                        font-size: 27px;
                        margin: 0 0 32px;
                        font-weight: 300;
                    }
                    .top-description {
                        .make-sm-column(10);
                        .make-sm-column-offset(1);
                        font-size: 18px;
                        line-height: 27px;
                        font-weight: 300;
                        margin: 0 0 66px;
                    }
                    .featureboxes {
                        .make-sm-column(11);
                        .make-sm-column-offset(0.5);
                        .featurebox {
                            .make-sm-column(3);
                            .make-sm-column-offset(1);
                            .icon {
                                width: 60%;
                                background-color: @medgreen;
                                border-radius: 50%;
                                padding-bottom: 60%;
                                margin: 0 auto 20px; 
                                position: relative;
                                .oo {
                                    .full-absolute;       
                                    .o {
                                        display: table;
                                        width: 100%;
                                        height: 100%;
                                        .i {
                                            display: table-cell;
                                            vertical-align: middle;
                                            text-align: center;
                                            .image {
                                                display: block;
                                                position: relative;
                                                width: 70%;
                                                left: 15%;
                                                top: 15%;
                                                img {
                                                    width: 100%;
                                                }
                                            }                                       
                                        }
                                    }                                    
                                }                                
                            }
                            .text {
                                color: @darkblue;
                                margin-bottom: 40px;
                                .title {
                                    text-align: center;
                                    font-size: 20px;
                                    font-weight: 600;
                                    margin:  0 0 11px;
                                }
                                .description {
                                    font-size: 17px;
                                    line-height: 24px;
                                    font-weight: 300;
                                    color: #223e48;
                                }
                            }
                        }
                    }
                }
            }
        }
        #bottom {
            padding: 61px 0 0;
            .left {
                .make-sm-column(7);
                 h3 {
                    font-size: 32px;
                    line-height: 34px;
                    margin: 0;
                    padding: 0 0 26px;
                }
                .content {
                    font-size: 20px;
                    line-height: 28px;
                    font-weight: 300;
                    margin: 0;
                    padding-bottom: 26px;
                }
                .phone {
                    font-size: 27px;
                    margin: 0 0 30px;
                    font-weight: 300;
                    .icon {
                        .contact-icon();
                        margin-right: 10px;
                        display: inline-block;
                    }                                       
                }
                .email {
                    font-size: 27px;
                    font-weight: 300;
                    margin-bottom: 25px;
                    .icon {
                        .contact-icon();
                        margin-right: 10px;
                        display: inline-block;
                    }
                }                
                @media (max-width: @screen-xs-max) { 
                    .phone,
                    .email {
                        font-size: 24px;
                    }
                }
            }
            .right {
                .make-sm-column(5);
                form {
                    // display: none;
                    label,
                    input,
                    select,
                    textarea {
                        display: block;
                        width: 100%;
                        font-size: 15px;
                        border: 1px solid #bbbcbc;
                        border-radius: 2px;    
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                    }
                    input, 
                    select {
                        border-radius: 2px;
                        padding: 10px;
                        margin-bottom: 14px;
                    }
                    label {
                        border: 0px;
                    }
                    select {
                        background: url('../../images/dropdown-arrow.png') no-repeat right center;
                    }
                    input[type='submit'] {
                        border: 0px;
                        border-radius: 0px;
                        background-color: #e6ae34;
                        margin: 14px 0 94px;
                        padding: 12px 5px;
                        color: #fff;
                    }                    
                    textarea {
                        resize: none;
                        height: 135px;
                        padding: 10px;
                    }
                }
                .complete {
                    // display: none;
                    margin: 100px 0;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 100;
                    .tick {
                        background-color: #e6ae34;
                        display: inline-block;
                        color: #fff;
                        width: 83px;
                        height: 83px;
                        border-radius: 42px;
                        font-size: 40px;
                        padding: 10px 0 0;
                        margin: 0 0 20px;
                    }
                    span {
                        display: block;
                        font-weight: 300;
                        font-size: 22px;
                    }
                    .another {
                        background-color: #e6ae34;
                        display: inline-block;
                        color: #fff;
                        font-weight: 300;
                        border-radius: 2px;
                        margin: 10px 0 0;
                        padding: 5px 15px;
                        cursor: pointer;
                        &:hover {
                            background-color: darken(#e6ae34, 10%);
                        }
                    }
                }
            }
        }
    }
}