body.template-article {
    main {
        #header {            
            background-position: center;
            text-align: center;
            color: #fff;
            position: relative;
            .colour-overlay {
                background-color: rgba(35, 56, 23, 0.85);
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 1;
            }            
            .container {
                position: relative;
                z-index: 2;
                .row {
                    a {
                        .make-sm-column(12);
                        font-size: 16px;
                        color: #fff;
                        text-align: left;
                        margin: 30px 0 0;
                        display: inline-block;
                        .transition;
                        &:hover {
                            text-decoration: none;
                            color: @gold;
                        }
                    }
                    h1 {
                        .make-sm-column(10);
                        .make-sm-column-offset(1);
                        font-size: 30px;
                        line-height: 38px;
                    }
                    .date {
                        .make-sm-column(12);
                        font-weight: 100;
                        font-size: 20px;
                        line-height: 20px;
                        padding: 0 0 70px;
                    }
                }                
            }
        }
        #content {
            background-color: #f3f5f1;
            .container {
                .top-image {
                    position: relative;
                    z-index: 3;
                    margin-top: -27px;
                    padding: 27px 32px 0;
                    background-color: #fff;                    
                    img {
                        width: 100%;
                        position: relative;
                    }
                }
                .text {
                    background-color: #fff;
                    overflow: hidden;
                    margin: 0 0 66px;
                    padding: 27px 29px 0;
                    font-size: 20px;
                    line-height: 28px;
                    font-weight: 300;
                    p {
                        margin: 0 0 25px;
                    }
                }
            }
        }
    }
}