.bg-cover() {
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    -ms-behavior: url('/backgroundsize.htc');
}

.contact-icon() {
    background-color: #61703d;
    border-radius: 22px;
    color: #fff;
    width: 44px;
    height: 44px;
    font-size: 22px;
    text-align: center;
    padding: 5px 0;
}

.product-button() {    
    color: #fff;
    display: block;
    font-size: 17px;
    text-align: center;
    background-color: @gold;
    line-height: 47px;
    margin-top: 13px;
    cursor: pointer;
    .transition();
    i {
        margin-right: 10px;
    }
}
.full-absolute() {    
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}