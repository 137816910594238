body {
    &.nav-open {
        .body-overflow {
            header {
                left: -260px;
                right: 260px;
            }
            .body-container {
                left: -260px;
            }
            .body-overlay {
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 1000;
                cursor: pointer;
            }
            nav#mobile-menu {
                right: 0;
                z-index: 1050;
            }
        }
    }
    .body-overflow {
        overflow: hidden;
        header {
            transition: left 0.25s, right 0.25s;
        }
        .body-container {
            position: relative;
            transition: 0.25s;
            left: 0;
        }
        nav#mobile-menu {
            position: fixed;
            top: 0;
            right: -260px;
            bottom: 0;
            width: 260px;
            background: #fff;
            overflow: scroll;
            transition: 0.25s;
            ul {
                .list-unstyled;
                font-size: 1.056rem;
                font-weight: normal;
                margin: 0;
                padding: 0;
                li {
                    position: relative;
                    border-bottom: 1px solid @darkgreen;
                    a {
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        color: @medgreen;
                        display: block;
                        margin: 0;
                        font-size: 18px;
                        padding: 18px;
                        padding-right: 66px;
                        position: relative;
                        text-decoration: none;
                        span.icon {
                            .square(66px);
                            line-height: 66px;
                            text-align: center;
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            font-size: 0.9444rem;
                        }
                    }
                }
            }
        }
    }
}
