body.template-productlist {
    main {
        #banner {            
            background-position: center;
            text-align: center;
            color: #fff;
            position: relative;
            padding: 80px 0 73px;
            .colour-overlay {
                background-color: rgba(35, 56, 23, 0.85);
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 1;
            }            
            .container {
                position: relative;
                z-index: 2;
                .row {                   
                    h1 {                        
                        font-size: 30px;
                        line-height: 30px;
                        margin: 0;
                        font-weight: 300;
                        padding: 0 0 8px;
                    }    
                    h2 {
                        font-size: 27px;
                        line-height: 27px;
                        margin: 0;
                        font-weight: 100;
                    }                
                }                
            }
        } 
        #products {     
            margin-bottom: 10px;
            font-size: 0;
            .back-button {
                .make-sm-column(12);
                a {
                    display: inline-block;
                    background-color: @gold;
                    color: #fff;
                    font-size: 14px;
                    line-height: 14px;
                    padding: 12px;
                    border-radius: 2px;
                    margin: 31px 0;
                    &:hover {
                        background-color: darken(@gold, 10%);
                        text-decoration: none;
                    }
                }
            }
            .product {
                margin-bottom: 58px;
                .make-sm-column(6);
                .make-md-column(4);
                @media (min-width: @screen-sm-min) {
                    float: none;
                    display: inline-block;
                    vertical-align: top;
                    
                    
                }
                .inner {
                    .image {
                        border: 1px solid #c1c2c2;
                        padding-bottom: 100%;
                        width: 100%;
                        height: 0;             
                        position: relative;
                        overflow: hidden;
                        display: block;
                        
                        
                       .o {
                            display: table;
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            .i {
                                display: table-cell;
                                vertical-align: middle;
                            }
                        }
                        
                        img {
                            width: 100%;
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                    .title {
                        font-size: 22px;
                        line-height: 22px;
                        color: @medgreen;
                        text-align: center;
                        font-weight: 600;
                        margin: 10px 0 24px;
                    }
                    .description {
                        font-size: 17px;
                        line-height: 23px;
                        font-weight: 300;
                    }
                    .button {
                        display: inline-block;
                        background-color: @gold;
                        color: #fff;
                        text-decoration: none;
                        padding: 12px 13px;
                        font-size: 16px;
                        i {
                            margin-left: 30px;
                        }
                        &:hover {
                            text-decoration: none;
                            background-color: darken(@gold, 10%);
                        }
                    }
                }
            }
        }
    }
}