body.template-contact {
    main{
        #header {
            background-position: center;
            position: relative;
            overflow: hidden;
            margin-bottom: 63px;
            .inner {
                position: relative;
                z-index: 3;
                text-align: center;
                margin: 70px 0 80px;

                h1 {
                    margin: 0;
                    color: #fff;
                    font-size: 32px;
                    padding: 0 0 10px;
                    font-weight: 300;
                    line-height: 100%;
                }
                h2 {
                    margin: 0;
                    color: #fff;
                    font-size: 27px;
                    font-weight: 100;
                    line-height: 100%;
                }
            }
            .colour-overlay {
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background-color: rgba(35, 56, 23, 0.83);
            }
        }
        .container {
            .row {
                #left {
                    .make-sm-column(7);
                    h2 {
                        font-size: 32px;
                        margin: 0 0 30px;
                    }
                    .content {
                        font-size: 20px;
                        line-height: 28px;
                        font-weight: 300;
                        margin-bottom: 30px;
                    }
                    .numbers {
                        font-size: 22px;
                        margin: 0 0 30px;
                        .icon {
                            .contact-icon();
                        }
                        table {
                            tbody {
                                tr {
                                    td {
                                        padding-top: 10px;
                                    }
                                    .number {
                                        font-weight: 100;
                                        padding-left: 30px;
                                    }
                                }
                            }
                        }  
                        @media (max-width: @screen-xs) { 
                            font-size: 21px;
                        }                      
                    }
                    .email {
                        font-size: 22px;
                        margin-bottom: 30px;
                        .icon {
                            .contact-icon();
                            display: inline-block;
                        }
                        a {
                            color: @darkblue;
                        }
                    }
                }
                #right {
                    .make-sm-column(5);
                    form {
                        // display: none;
                        label,
                        input,
                        select,
                        textarea {
                            display: block;
                            width: 100%;
                            font-size: 15px;
                            border: 1px solid #bbbcbc;
                            border-radius: 3px;                        
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            appearance: none;
                        }
                        input, 
                        select {
                            border-radius: 2px;
                            padding: 10px 5px 11px;
                            margin-bottom: 15px;
                        }
                        label {
                            border: 0px;
                        }
                        select {
                            background: url('../../images/dropdown-arrow.png') no-repeat right center;
                        }
                        input[type='submit'] {
                            border: 0px;
                            border-radius: 0px;
                            background-color: #e6ae34;
                            margin: 20px 0 94px;
                            color: #fff;
                        }
                        textarea {
                            resize: none;
                            height: 135px;
                            padding: 10px;
                        }
                    }
                    .complete {
                        // display: none;
                        margin: 100px 0;
                        text-align: center;
                        font-size: 18px;
                        font-weight: 100;
                        .tick {
                            background-color: #e6ae34;
                            display: inline-block;
                            color: #fff;
                            width: 83px;
                            height: 83px;
                            border-radius: 42px;
                            font-size: 40px;
                            padding: 10px 0 0;
                            margin: 0 0 20px;
                        }
                        span {
                            display: block;
                            font-weight: 300;
                            font-size: 22px;
                        }
                        .another {
                            background-color: #e6ae34;
                            display: inline-block;
                            color: #fff;
                            font-weight: 300;
                            border-radius: 2px;
                            margin: 10px 0 0;
                            padding: 5px 15px;
                            cursor: pointer;
                            &:hover {
                                background-color: darken(#e6ae34, 10%);
                            }
                        }
                    }
                }
            }
        }
    }
}