footer {
    background-color: @medgreen;
    padding: 30px 0;
    color: #fff;
    .left {
        .make-sm-column(7);
        margin-bottom: 20px;
        @media (min-width: @screen-sm-min) {
            margin-bottom: 0;
            span {
                margin-right: 23px;
            }
        }
        span {
            display: inline-block;
        }
    }
    .right {
        .make-sm-column(5);
        text-align: right;
        .made-by {
            display: inline-block;
            @media (min-width: @screen-md) {
                margin-left: 20px;
            }
        }
        span {
            display: block;
            margin-bottom: 10px;
            &:first-child {
                margin-left: 0;
            }
            @media (min-width: @screen-sm-min) {
                margin-left: 5px;
                display: inline-block;
                margin-bottom: 0;
            }
        }
        a {
            color: #fff;
            text-decoration: underline;
            transition: 0.2s ease;
            display: inline-block;

            &:hover {
                color: @gold;
            }
        }
    }
    @media (max-width: @screen-sm-min) {
        .left,
        .right {
            float: none;
            text-align: center;
        }
    }
}