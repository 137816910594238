body.template-product {
    main {
        #banner {            
            background-position: center;
            text-align: center;
            color: #fff;
            position: relative;
            .colour-overlay {
                background-color: rgba(35, 56, 23, 0.85);
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 1;
            }            
            .container {
                position: relative;
                z-index: 2;
                .row {                   
                    h1 {                        
                        font-size: 32px;
                        line-height: 32px;  
                        margin: 0;
                        padding: 44px 0 7px;
                        font-weight: 300;
                    }   
                    h2 {
                        font-weight: 100;
                        font-size: 27px;
                        margin: 0 0 44px;
                    }                 
                }                
            }
        } 
        #success { 
            margin: 20px 0 0;
        }
        #content {
            overflow: hidden;
            .back-button {
                .make-sm-column(12);
                a {
                    display: inline-block;
                    background-color: @gold;
                    color: #fff;
                    font-size: 14px;
                    line-height: 14px;
                    padding: 10px 18px;
                    border-radius: 2px;
                    margin: 30px 2px;
                    &:hover {
                        background-color: darken(@gold, 10%);
                        text-decoration: none;
                    }
                }
            }
            .left {
                .make-sm-column(5);
                .image {
                    border: 1px solid #c1c2c2;
                    img {
                        width: 100%;
                    }
                }
                .book {
                    .product-button();
                    &:hover {
                        background-color: darken(@gold, 10%);
                    }
                }
                .ask {
                    .product-button();
                    background-color: @medgreen;
                    &:hover {
                        background-color: darken(@medgreen, 10%);
                    }
                }
                .pdfs {
                    margin-bottom: 83px;
                    .pdf {   
                    padding: 12px 0 0;                     
                        a {
                            display: table;
                            width: 100%;
                            border: 1px solid #c6c7c7;
                            color: #263f52;
                            line-height: 23px;
                            font-size: 17px;
                            padding: 14px 17px;
                            font-weight: 400;
                            text-decoration: none;
                            .transition();
                            &:hover {
                                background-color: darken(white, 10%)
                            }
                            i {
                                padding-right: 14px;
                                display: table-cell;
                                color: @gold;
                                vertical-align: middle;
                            }
                            span {
                                display: table-cell;
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
            .right {
                .make-sm-column(7);
                margin-bottom: 83px;
                h2 {
                    color: @medgreen;
                    font-size: 24px;
                    line-height: 24px;
                    margin: 0 0 28px;
                    font-weight: 400;
                    padding: 0 0 0 16px;
                }
                .description {
                    font-size: 17px;
                    line-height: 23px;
                    color: @darkblue;
                    padding: 0 0 0 16px;
                    font-weight: 400;
                    p {
                        margin-bottom: 46px;
                    }
                    ul {
                        margin: -21px 0 51px;
                        li {
                            margin: 0 0 24px;
                            padding: 0;
                        }
                    }
                    table {
                        width: 100%;
                        border: 1px solid #d7d7d7;
                        thead {
                            background-color: #ebeee9;
                            line-height: 44px;
                        }
                        tbody {
                            tr {
                                td {
                                    border-right: 1px solid #d7d7d7;
                                }
                            }
                        }
                    }
                    @media (max-width: @screen-sm) { 
                        padding: 0;
                    }     
                }
            }
        }
    }
    .modal {
        .modal-content {
            color: @darkblue;    
            border-radius: 2px;   
            .modal-header {
                border: 0px;
                padding: 24px 28px 28px;
                h2 {
                    font-size: 22px;
                    line-height: 22px;
                    font-weight: 300;
                    margin: 0;
                    color: #263f52;
                }
                h3 {
                    margin: 0;
                    padding: 8px 0 0;
                    font-size: 22px;
                    line-height: 22px;
                    font-weight: 400;
                }
            }
            .modal-body {
                padding: 0 31px 0 28px;
                form {
                    overflow: hidden;
                    label {
                        font-weight: 600;
                        font-size: 16px;
                    }
                    input,
                    textarea {
                        width: 100%;
                        font-size: 18px;
                        line-height: 24px;
                        margin: 0 0 14px;
                        border: 1px solid #b3b7b7;
                        border-radius: 2px;
                        padding: 10px 15px 11px;
                        &[type='submit'] {
                            background-color: @gold;
                            color: #fff;
                            border: 0;
                            text-align: center;
                            float: right;
                            width: auto;
                            margin: 13px 0 31px;
                            text-align: center;
                            padding: 0 13px;
                            line-height: 36px;
                            &:hover {
                                background-color: darken(@gold, 10%);
                            }
                        }
                    }
                    textarea {
                        resize: none;
                        height: 146px;
                        padding: 10px;
                    }
                }
            }
        }
        &#bookModal {      
            .modal-content { 
                .modal-header {
                    h2 {
                        font-size: 24px;
                        line-height: 24px;
                        color: #61703d;
                        font-weight: 600;
                    }
                    h3 {
                        font-weight: 300;
                    }
                }
            }
        }
    }
}