body.template-about {
    section#intro {
        position: relative;
        color: #fff;
        background-position: center center;
        .bg-cover;
        .colour-overlay {
            background-color: rgba(35, 56, 23, 0.85);
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 1;
        }
        .container {
            position: relative;
            z-index: 2;
            .row {
                h1 {
                    text-align: center;
                    padding: 70px 0 0;
                    font-size: 32px;
                    line-height: 32px;
                    font-weight: 300;
                    margin: 0;
                }
                h2 {
                    text-align: center;
                    font-weight: 100;
                    margin: 0;
                    padding: 11px 0 30px;
                }
                .intro-content {
                    .make-sm-column(10);
                    .make-sm-column-offset(1);
                    padding-bottom: 54px;
                    font-size: 18px;
                    line-height: 27px;
                    font-weight: 100;
                }
            }
        }
    }
    section#team {
        .container {
            .row {
                h2 {
                    text-align: center;
                    color: #223f06;
                    padding: 61px 0 20px;
                    margin: 0;
                    font-size: 30px;
                    line-height: 30px;
                    font-weight: 400;
                }
                .team-content {
                    .make-sm-column(10);
                    .make-sm-column-offset(1);
                    font-size: 20px;
                    font-weight: 100;
                    line-height: 28px;
                    padding-bottom: 50px;                               
                }
                .members {
                    .make-sm-column(12);
                    .make-sm-column-offset(-0.5);
                    font-size: 0;
                    .member {
                        .make-sm-column(3);
                        .make-sm-column-offset(1);      
                        display: inline-block;
                        vertical-align: top;                        
                        .image {
                            text-align: center;
                            position: relative;
                            padding-bottom: 100%;
                            .inner {    
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                border-radius: 50%;
                                .bg-cover;
                            }
                        }
                        .text {
                            h3 {
                                text-align: center;
                                font-size: 20px;
                                font-weight: 700;
                                line-height: 20px;
                                margin: 0;
                                padding: 23px 0 0;
                                color: @darkblue;
                            }
                            h4 {
                                text-align: center;
                                margin: 0;
                                font-size: 16px;
                                line-height: 16px;
                                font-weight: 300;
                                padding: 3px 0 0;
                                color: #868788;
                            }
                            .description {
                                font-size: 16px;
                                line-height: 23px;
                                font-weight: 300;
                                padding: 17px 0 0;
                                color: @darkblue;
                            }
                            .social {
                                text-align: center;
                                margin: 27px 0 80px;
                                a {
                                    display: inline-block;
                                    border-radius: 13px;
                                    width: 26px;
                                    height: 26px;
                                    color: #fff;   
                                    background-color: #3088ad;      
                                    padding: 2px;   
                                    margin-right: 5px;                      
                                }
                                .twitter {
                                    background-color: #35b4cb;
                                }
                                .instagram {
                                    background-color: #3d7189;
                                }

                            }
                        }
                        @media(min-width: @screen-sm-min) {
                            float: none;                            
                        }
                    }
                }
            }
        }
    }
    section#outro {
        position: relative;
        color: #fff;
        background-position: center center;
        .bg-cover;
        .colour-overlay {
            background-color: rgba(20, 37, 45, 0.66);
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 1;
        } 
        .container {
            position: relative;
            z-index: 2;
            h2 {
                font-size: 38px;
                padding: 66px 0 46px;
                margin: 0;
                font-weight: 400;
            }
            .outro-content {
                .make-sm-column(11);
                font-size: 20px;
                line-height: 28px;
                font-weight: 100;
                padding: 0 0 55px;
                p {
                    margin-bottom: 30px;
                }
            }            
        }
    }
}