#assistance {
    background-color: #ebeee9;
    text-align: center;
    padding: 102px 0 94px;
    .container {
        .row {
            h2 {
                margin: 0;
                color: @medgreen;
                font-size: 32px;
                line-height: 32px;
                padding: 0 0 14px;
            }
            h3 {
                margin: 0;
                color: @medgreen;
                font-size: 27px;
                line-height: 27px;
                padding: 0 0 37px;
                font-weight: 300;
            }
            a {
                display: inline-block;
                color: #fff;
                background-color: @gold;
                padding: 0 26px;
                font-size: 17px;
                line-height: 34px;
                .transition;
                &:hover {
                    background-color: darken(@gold, 10%);
                    text-decoration: none;
                }
            }
        }
    }
}