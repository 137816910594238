body.template-general,
body.status-404,
body.status-500 {
    main {
        #header {
            overflow: hidden;
            padding: 55px 0 69px;
            background-position: center center;
            position: relative;
            .bg-cover;
            .colour-overlay {
                background-color: rgba(35, 56, 23, 0.85);
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 1;
            }
            h1 {
                text-align: center;
                color: #ffffff;
                position: relative;
                z-index: 2;
                font-weight: 300;
            }
        }
        #content {
            background-color: #f3f5f1;
            .content {
                margin: 35px 0 70px;
                background-color: #ffffff;
                padding: 26px 23px;
                font-size: 18px;
                h2 {
                    margin: 0 0 20px;
                }
            }
        }
    }
}