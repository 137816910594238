@import url(https://fonts.googleapis.com/css?family=Lato:400,100,100italic,300,300italic,400italic,700,700italic,900,900italic);
body {
    padding-top: 100px;
    font-family: 'Lato', sans-serif;
    @media (max-width: @screen-sm-min) { 
        padding-top: 47px;
    }
    header {
        background-color: #fff;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        padding: 0 0 15px;    
        z-index: 9;  
        box-shadow: 0px -7px 10px #000;    
        @media (max-width: @screen-sm-min) { 
            padding: 0;
        }
        .top-header {
            background-color: #e6e7e8;
            margin: 0 0 10px;
            padding: 0;
            .hidden-xs;
            @media (max-width: @screen-sm-max) {    
                font-size: 11px;
                text-align: center;
            }
            .container {
                .inner {
                    width: 100%;
                    padding: 4px 0;
                    font-size: 0;
                    .number {
                        display: inline-block;
                        width: auto;
                        font-weight: 400;
                        color: #1f383f;
                        font-size: 11px;
                        margin-right: 36px;
                        &:last-child {
                            margin-right: 0;
                        }
                        
                        @media (min-width: @screen-md-min) {
                            font-size: 14px;
                            margin-right: 52px;
                        }
                        @media (min-width: @screen-lg-min) {
                            margin-right: 102px;

                        }
                        a {
                            color: #1f383f;
                            font-weight: 300;
                        }
                    }
                }
            }
        }
        .container {
            .logo {
                float: left;
                a {
                    background-image: url('../../images/logo.jpg');
                    width: 180px;
                    height: 47px;
                    display: block;
                    overflow: hidden;
                    text-indent: -999px;
                }
            }
            .toggle {
                float: right;
                .visible-xs();
                a {
                    color: #FFF;
                    display: block;
                    padding: 13px;
                    transform: scale(1);
                    transition: 0.5s;
                    &:hover {
                        transform: scale(1.2);                 
                    }
                }
                span.menu-toggle {
                    display: block;
                    span {
                        background: @darkgreen;
                        height: 3px;
                        display: block;
                        margin-top: 6px;
                        width: 29px;
                        transition: 0.5s;
                        &:first-child {
                            margin-top: 0;
                        }
                    }
                }
            }
            #nav {
                .hidden-xs;
                float: left;
                nav {
                    ul {
                        list-style-type: none;
                        font-size: 0;
                        margin: 0;
                        padding: 14px 0 0;
                        li {
                            display: inline-block;
                            font-size: 16px;
                            line-height: 16px;
                            border-bottom: 3px solid rgba(255, 255, 255, 0);  
                            margin: 0 16px;
                            transition: 0.2s ease; 
                            @media (max-width: @screen-sm-max) { 
                                margin: 0 10px;
                            }
                            &.active {
                                border-bottom: 3px solid @gold;
                            }
                            a {
                                color: @darkblue;
                                display: block;
                                padding: 4px 4px 3px;
                                text-decoration: none;                                
                                &:hover {
                                    text-decoration: none;
                                }
                            }
                            &:hover {
                                border-bottom: 3px solid @gold;
                            }
                        }
                    }
                }
            }
            .social {
                float: right;
                @media (max-width: @screen-sm-max) { 
                    .hidden();
                }
                @media (max-width: @screen-sm-max) { 
                    position: absolute;
                    bottom: -49px;
                    right: 225px;
                }
                ul {
                    list-style-type: none;
                    font-size: 0;
                    padding: 14px 0 0;
                    margin: 0;
                    li {
                        display: inline-block;
                        margin-left: 11px;
                        a {
                            color: #fff;
                            text-align: center;
                            display: block;
                            font-size: 15px;
                            line-height: 26px;
                            width: 26px;
                            height: 26px;
                            border-radius: 13px;
                            display: inline-block;
                            .transition();
                        }
                        .facebook {
                            background-color: #3088ad;
                            &:hover{                                                           
                                background: darken(#3088ad, 10%);
                            }
                        }
                        .twitter {
                            background-color: #35b4cb;
                            &:hover{                                                           
                                background: darken(#35b4cb, 10%);
                            }
                        }
                        .instagram {
                            background-color: #3d7189;
                            &:hover{                                                           
                                background: darken(#3d7189, 10%);
                            }
                        }
                    }                    
                }
            }
        }
        .container {
            position: relative;
            .search {
                .hidden-xs;
                position: absolute;
                right: 0;   
                bottom: -49px;
                background-color: #e6e7e8;        
                padding: 6px 7px; 
                .search-icon {
                    position: absolute;
                    left: 20px;
                    color: @darkblue;
                }
                form {
                    input {
                        border-radius: 100px;
                        border: 0px;
                        padding-left: 40px;
                    }
                } 
            }
        }        
    }
}