body.template-products {
    main {
        #header {
            .bg-cover;
            overflow: hidden;
            padding: 83px 0 69px;
            background-position: center center;
            position: relative;
            .colour-overlay {
                background-color: rgba(35, 56, 23, 0.85);
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 1;
            } 
            h1 {
                text-align: center;
                color: #fff;
                position: relative;
                z-index: 2;
                margin: 0;
                font-weight: 300;
                font-size: 32px;
                line-height: 32px;
                padding: 0 0 6px;
            }
            h2 {
                text-align: center;
                color: #fff;
                position: relative;
                z-index: 2;
                font-weight: 100;
                margin: 0;
            }  
        }
        #intro {
                padding: 41px 0 0;
            h2 {
                text-align: center;
                font-size: 30px;
                line-height: 30px;
                color: #223f06;
                font-weight: 400;
                padding-bottom: 10px;
            }
            .content {
                .make-sm-column(10);
                .make-sm-column-offset(1);
                font-size: 20px;
                line-height: 28px;
                font-weight: 300;
            }
        }
        #categories {
            margin: 60px 0 107px;
            .container {
                .row {
                    .category {
                        .make-sm-column(4);
                        text-align: center;
                        margin-bottom: 20px;
                        &:nth-child(5) {
                            .make-sm-column(8);
                        }
                        .inner {
                            background-color: #ebeee9;
                            padding: 32px 15px 35px;
                            position: relative;
                            a {
                                position: absolute;
                                display: block;
                                top: 0;
                                left: 0;
                                bottom: 0;
                                right: 0;
                                text-indent: -9999px;
                                overflow: hidden;
                            }
                            .icon {
                                display: inline-block;
                                height: 127px;
                                width: 127px;
                                background-color: #61703d;
                                background-position: center;
                                background-repeat: no-repeat;
                                border-radius: 64px;
                                overflow: hidden;
                                img {
                                    display: block;
                                    position: relative;
                                    width: 70%;
                                    top: 15%;
                                    left: 15%;
                                    &.logo {
                                        width: 100%;
                                        top: 0;
                                        left: 0;
                                    }
                                }
                            }
                            .text {
                                font-size: 20px;
                                line-height: 28px;
                                padding: 24px 0 0;
                                color: #244051;
                                height: 56px;
                                text-decoration: underline;
                                @media (min-width: 768px) and (max-width: 991px) {
                                    height: 110px;
                                }
                            } 
                            &:hover {
                                background-color: darken(#ebeee9, 10%);
                            }  
                        }                     
                    }
                }
            }
        }
    }
}