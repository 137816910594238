body.template-homepage {
    main {
        #banner {
            padding: 65px 0 69px;
            background-position: center center;
            .container {
                .row {
                    .title {
                        display: inline-block;      
                        padding: 18px 19px 21px;
                        color: #fff;
                        font-size: 30px;
                        line-height: 30px;
                        background-color: fade(@gold, 85%);
                        @media (min-width: @screen-sm) { 
                            margin: 0 50% 14px 0;
                            background-color: @medgreen;
                        }  
                    }
                    .description {
                        .make-sm-column(6);
                        float: none;
                        background-color: rgba(0, 0, 0, 0.7);
                        color: #fff;
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 300;
                        padding: 17px 22px;
                        margin: 0 0 13px;
                        .banner-link {
                            display: inline-block;
                            position: relative;
                            background-color: @medgreen;
                            font-weight: 400;
                            color: #fff;
                            font-size: 16px;
                            line-height: 16px;
                            padding: 11px 76px 12px 14px;
                            transition: 0.2s ease; 
                            margin: 0 0 11px -1px;
                            i {
                                position: absolute;
                                right: 11px;
                                top: 12px;
                            }
                            &:hover {
                                text-decoration: none;
                                background-color: darken(@gold, 10%);
                            }
                        }
                    }
                }
            }
        }
        #intro {
            .container {
                .row {
                    h1 {
                        text-align: center;
                        font-size: 30px;
                        font-weight: 400;
                        margin: 70px 0 30px;
                    }
                    .intro-content {
                        .make-sm-column(10);
                        .make-sm-column-offset(1);
                        font-size: 20px;
                        line-height: 24px;
                        font-weight: 300;
                    }
                    .featureboxes {
                        .make-sm-column(12);
                        .make-sm-column-offset(-0.5);
                        width: 100%;
                        position: relative;
                        padding-bottom: 50px;
                        .featurebox {                            
                            .make-sm-column(3);
                            .make-sm-column-offset(1);
                            .image {
                                margin: 0 auto;
                                padding: 65px 50px 25px;
                                max-width: 300px;   
                                .image-inner {
                                    background-color: @medgreen;
                                    padding-bottom: 100%;
                                    border-radius: 50%;
                                    position: relative;
                                    text-align: center;
                                    overflow: hidden;
                                    img {
                                        position: absolute;
                                        width: 70%;
                                        left: 15%;
                                        top: 15%;
                                    }
                                }                                
                            }
                            .text {
                                .title {
                                    text-align: center;
                                    font-size: 20px;
                                    font-weight: 600;
                                    margin: 0 0 30px;
                                }
                                .description {
                                    font-size: 16px;
                                    font-weight: 300;
                                    margin: 0 0 30px;
                                }
                                .link {
                                    width: 100%;
                                    text-align: center;
                                     a {    
                                        display: inline-block;                                
                                        text-align: center;
                                        background: @gold;
                                        color: #fff;
                                        padding: 13px;
                                        font-size: 14px;
                                        transition: 0.2s ease; 
                                        &:hover {
                                            text-decoration: none;
                                            background: darken(@gold, 10%);
                                        }
                                        i {
                                            margin:  0 0 0 20px;
                                            display: inline-block;
                                            vertical-align: middle;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        #products {
            background: #f2f2f2;
            .container {
                .row {                    
                    h2 {
                        margin: 70px 0 30px;
                        text-align: center;
                        font-size: 30px;
                        font-weight: 400;
                    }
                    .products-content {
                        .make-sm-column(10);
                        .make-sm-column-offset(1);
                        font-size: 20px;
                        line-height: 24px;
                        font-weight: 300;
                        padding: 0 15px 37px;
                    }
                    .product-section {    
                        clear: both;
                        width: 100%;   
                        margin: 0 0 33px;
                        .section {
                            padding: 0 0 33px;
                            .title {
                                color: #fff;
                                padding: 13px 24px 16px 21px;
                                font-size: 20px;
                                font-weight: 700;
                                background: #61703d;
                            }
                            .subsections {
                                background: #fff;
                                padding: 24px 23px;
                                .title {
                                    padding: 0;
                                    color: #1e3644;
                                    font-size: 17px;
                                    background: none;
                                }
                                .product-list {
                                    padding: 14px 0 12px;
                                    a {
                                        display: inline-block;
                                        border: 1px solid #d8d8d8;
                                        color: @gold;
                                        text-decoration: underline;
                                        font-size: 17px;
                                        line-height: 17px;
                                        padding: 10px 16px 12px;
                                        margin: 0 11px 11px 0;
                                    }
                                }
                            }               
                        }                        
                    }
                }
            }
        }
    }
}